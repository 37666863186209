<template>
  <div class='app-container'>
    <el-page-header @back="goBack" class="header">
      <template slot="content">
        管理定制：南京理工大学-计算机科学与工程学院
      </template>
    </el-page-header>
    <el-divider style="margin:0px"></el-divider>
    <el-card :body-style="{padding: '0px'}" shadow="hover" class="box">
      <div title="添加送审规则" @click="insertManageClick"
        style="text-align:center;font-size:100px;line-height:230px;color:rgb(187, 187, 187);">
        <i class="el-icon-plus"></i>
      </div>
    </el-card>
    <el-card v-for="index in 2" shadow="hover" :body-style="{padding: '0px'}" class="box">
      <!-- <div style="text-align:center">
        <img src="@/assets/img/paper.png" class="image">
      </div>
      <div class="text">
        学位类型：专业硕士
      </div> -->
      <div class="header">
        2022年送审
      </div>
      <div class="text">
        参与单位:
        <div class="institution" v-html="totalInstitution.join('<br/>')"></div>
      </div>
      <div class="tool">
        <el-button type="success" size="mini" icon="el-icon-view" circle @click="lookClick"></el-button>
        <el-button type="primary" size="mini" icon="el-icon-edit" circle @click='editClick'></el-button>
        <el-popconfirm style="margin-left:10px" confirm-button-text='是' cancel-button-text='否' icon="el-icon-info"
          icon-color="red" title="是否删除？">
          <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" circle></el-button>
        </el-popconfirm>
      </div>
    </el-card>

    <vxe-modal v-model="insertModel" id="insertModel" width="1000" height="100%" :show-footer='true' show-zoom resize
      :transfer='true'>
      <template #title>
        <span>{{isInsert?'添加管理规则':'编辑管理规则'}}</span>
      </template>
      <div>
        <el-card shadow='never' class="box-card">
          <div slot="header" class="clearfix">
            <span>选择参与单位</span>
          </div>
          <div>
            <el-form :model="form" label-width="120px">
              <el-form-item label="教务单位：">
                <el-checkbox-group v-model="checkList1">
                  <el-checkbox v-for="item in institutionList1" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="教育单位：">
                <el-checkbox-group v-model="checkList2">
                  <el-checkbox v-for="item in institutionList2" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="导师：">
                <el-checkbox-group v-model="checkList3">
                  <el-checkbox v-for="item in institutionList3" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="学生：">
                <el-checkbox-group v-model="checkList4">
                  <el-checkbox v-for="item in institutionList4" :label="item"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </el-card>

        <el-card shadow='never' class="box-card">
          <template slot="header">
            <span>单位权限设置</span>
          </template>
          <div v-for="item in totalInstitution" :key='item'>
            <div class="authBox">
              <div class="authBox-header">
                {{item}}
              </div>
              <div class="authBox-content">
                <el-checkbox-group v-model="selectsAuth[item]">
                  <el-checkbox v-for="item in authList" :label="item"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <template #footer>
        <el-button type="primary" size="medium">提交</el-button>
      </template>
    </vxe-modal>
    <vxe-modal v-model="lookModel" width="1000" height="100%" show-zoom resize :transfer='true'>
      <template #title>
        <span>查看送审规则</span>
      </template>
      <el-descriptions :column="2" :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}" border>
        <el-descriptions-item label="管理规则名称" :span="2">2022年送审</el-descriptions-item>
        <el-descriptions-item label="参与单位" :span="2" :labelStyle="{'text-align':'center'}" :contentStyle="{'text-align':'center'}">参与权限</el-descriptions-item>
        </el-descriptions-item>
        <el-descriptions-item v-for="item in totalInstitution" :label="item" :span="2">
          <el-tag v-for='value in selectsAuth[item]' size="small">{{value}}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </vxe-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        insertModel: false,
        isInsert: false,
        lookModel: false,
        checkList1: [],
        checkList2: [],
        checkList3: [],
        checkList4: [],
        institutionList1: ['研究生院', '教务处'],
        institutionList2: ['计算机学院', '理学院', '机械与工程学院'],
        institutionList3: ['导师'],
        institutionList4: ['学生'],
        authList: ['上传论文', '查看送审进度', '审核论文','查看专家信息'],
        modelList: [],
        selectsAuth: {
          '研究生院': [],
          '教务处': [],
          '计算机学院': [],
          '理学院': [],
          '机械与工程学院': [],
          '导师': [],
          '学生': []
        }
      }
    },
    computed: {
      totalInstitution() {
        let total = [];
        this.checkList1.forEach(item => {
          total.push(item);
        });
        this.checkList2.forEach(item => {
          total.push(item);
        });
        this.checkList3.forEach(item => {
          total.push(item);
        });
        this.checkList4.forEach(item => {
          total.push(item);
        });
        return total;
      }
    },
    created(){
      this.institutionId = this.$route.params.id;
    },
    methods: {
      goBack() {
        this.$router.push({
          name: 'schoolCustom_index'
        })
      },
      insertManageClick() {
        this.insertModel = true;
        this.isInsert = true;
      },
      editClick() {
        this.insertModel = true;
        this.isInsert = false;
      },
      lookClick() {
        this.lookModel = true;
      }
    },
    components: {

    }
  }
</script>

<style scoped>
  .box {
    /* text-align: center; */
    width: 200px;
    height: 230px;
    margin: 0 20px 20px 0px;
    float: left;
  }

  .image {
    width: 130px;
    height: 150px;
  }

  .box .header {
    text-align: center;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
  }

  .box .text {
    /* color: rgb(187, 187, 187); */
    font-size: 16px;
    padding-bottom: 5px;
    margin-left: 20px;
  }

  .box .text .institution {
    color: rgb(187, 187, 187);
    font-size: 14px;
    overflow: auto;
    height: 126px;
  }

  .box .tool {
    /* float: right; */
    text-align: right;
    padding-right: 20px;
  }

  .box-card {
    margin-top: 10px;
  }

  .box-card .el-form-item {
    margin-bottom: 0px;
  }

  .authBox {
    margin-right: 10px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    max-height: 100%;
    box-sizing: border-box;
    text-align: center;
    float: left;
    /* position: relative; */
  }

  .authBox-header {
    height: 40px;
    line-height: 40px;
    background: #f5f7fa;
    margin: 0;
    /* padding-left: 15px; */
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
    color: #000;
  }

  .authBox-content {
    height: 160px;
    text-align: left;
    padding: 15px;
  }

  .authBox-content .el-checkbox {
    height: 30px;
    line-height: 30px;
    padding-left: 15px;
    display: block !important;
  }

  /*滚动条整体部分*/
  .text ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    display: none;
  }

  .text:hover ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    display: block;
  }

  /*滚动条的轨道*/
  .text ::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  }

  /*滚动条里面的小方块，能向上向下移动*/
  .text ::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }

  .text ::-webkit-scrollbar-thumb:hover {
    background-color: #A8A8A8;
  }

  .text ::-webkit-scrollbar-thumb:active {
    background-color: #787878;
  }

  /*边角，即两个滚动条的交汇处*/
  .text ::-webkit-scrollbar-corner {
    background-color: #FFFFFF;
  }
</style>

<style>
  .tool .el-button--mini.is-circle {
    padding: 4px;
  }

  .el-divider--horizontal {
    margin: 20px 0px;
  }

  .step .el-step__title {
    font-size: 14px;
  }

  .el-tag {
    margin-right: 10px;
  }

  .button-new-tag {
    /* margin-left: 10px; */
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    /* margin-left: 10px; */
    vertical-align: bottom;
  }
</style>